<template>
  <div class="container">
    <div class="header">
      <div class="header-wrap">
        <div class="header-img">
          <van-image class="header-van-image" :src="userInfo.avatarUrl" alt="" />
        </div>
        <van-uploader :after-read="afterRead">
          <van-button plain size="small" round icon="photo" type="primary">上传头像</van-button>
        </van-uploader>
      </div>
    </div>
    <div class="main">
      <van-cell-group>
        <van-field v-model="userInfo.nickName" label="学员姓名" placeholder="请输入学员姓名" required />
        <van-field name="radio" label="学员性别" required>
          <template #input>
            <van-radio-group v-model="userInfo.sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="userInfo.birthday"
          label="学员生日"
          placeholder="点击选择时间"
          @click="showBirthdayPicker = true"
        />
        <van-popup v-model="showBirthdayPicker" position="bottom">
          <van-datetime-picker
            type="date"
            :value="dayjs(userInfo.birthday).$d"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onBirthdayConfirm"
            @cancel="showBirthdayPicker = false"
          />
        </van-popup>
        <van-field v-model="userInfo.height" type="number" label="学员身高" placeholder="请输入身高">
          <template #button>
            <div class="field-unit">cm</div>
          </template>
        </van-field>
        <van-field v-model="userInfo.weight" type="number" label="学员体重" placeholder="请输入体重">
          <template #button>
            <div class="field-unit">kg</div>
          </template>
        </van-field>

        <van-field
          readonly
          clickable
          label="所在地"
          :value="userInfo.address"
          placeholder="选择城市"
          @click="showAddressPicker = true"
        />
        <van-popup v-model="showAddressPicker" position="bottom">
          <van-area :area-list="areaList" v-model="areaValue" :columns-num="2" @cancel="showAddressPicker=false" @confirm="onAreaConfirm" />
        </van-popup>
        <van-field
          v-model="userInfo.health"
          rows="2"
          autosize
          label="健康状态"
          type="textarea"
          max-length="50"
          show-word-limit
          placeholder="健康状态"
        />

      </van-cell-group>
      <van-button type="primary" size="large" @click="onSubmit">确认添加</van-button>
    </div>

  </div>
</template>
<script>

import { Field, Uploader, Area, DatetimePicker, Radio, RadioGroup } from 'vant'
import areaList from '@/common/area'
import { isEmpty , isPhone } from '@/common/validate'
import upload from '@/utils/upload'
import PageMixin from '@/mixins/page'
import dayjs from 'dayjs'

export default {
  name: 'Profile',
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Area.name]: Area,
    [DatetimePicker.name]: DatetimePicker,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup
  },
  mixins: [PageMixin],
  data() {
    return {
      areaList,
      userInfo: {},
      areaValue:'',
      showAddressPicker: false,
      showBirthdayPicker: false,
      minDate: new Date('1970-01-01'),
      maxDate: new Date()
    }
  },
  methods: {
    dayjs,
    onSubmit() {
      if (isEmpty(this.userInfo.nickName)) {
        this.$toast('昵称不能为空')
        return
      }
      if (isEmpty(this.userInfo.sex)) {
        this.$toast('性别不能为空')
        return
      }
      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      const params = {
        student_name: this.userInfo.nickName,
        student_avatar: this.userInfo.avatarUrl,
        student_gender: this.userInfo.sex,
        student_birthday: this.userInfo.birthday,
        student_weight: this.userInfo.weight,
        student_height: this.userInfo.height,
        student_health_status: this.userInfo.health,
        student_address: this.userInfo.address,
      }
      this.$api.profile_add_kids(params)
        .then(res => {
          this.$toast('操作成功')
          this.$router.go(-1)
        }).catch(err => {
          console.error(err)
        })
    },
    afterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      upload({ file: file.file })
        .then(res => {
          this.userInfo.avatarUrl = res.path
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    onAreaConfirm(values) {
      this.userInfo.address = `${values[0].name},${values[1].name}`
      this.showAddressPicker = false
    },
    onBirthdayConfirm(val) {
      this.userInfo.birthday = dayjs(val).format('YYYY-MM-DD')
      this.showBirthdayPicker = false
    }
  }
}
</script>
<style lang="less" scoped>
  .header{
    height: 277px;
    background-image: url('~@/assets/user_bg.png');
    background-size: 100% 100%;
    &-wrap{
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 40px;
    }
    &-img{
      width:82px;
      height:82px;
      border-radius:50%;
      background: #fff;
      position: relative;
    }
    &-van-image{
      width:80px;
      height:80px;
      border-radius:50%;
      overflow:hidden;
      position: absolute;
      top: 1px;
      left: 1px;
    }
  }
  .main{
    margin:-130px 15px 15px 15px;
    border-radius: 8px;
    overflow: hidden;
    background:#fff;
  }
</style>

